import React, { useState, createContext, useContext, useEffect } from "react";
import classNames from "classnames";
import { postRequestData } from "../../utils/api-services";
import { TriggerBtnContext } from "../../App";
const ThemeContext = createContext();
const ThemeUpdateContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}
function defaultBrowserTheme() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  } else {
    return "light";
  }
}
const ThemeProvider = ({ ...props }) => {
  const { triggerFetch } = useContext(TriggerBtnContext);
  const defaultTheme = {
    main: "default", //other value can be passed "clean,shady,softy"
    sidebar: "white", //other value can be passed "light,dark,theme"
    appbar: "theme", //other value can be passed "light,white,dark"
    sidebarVisibility: false,
    sidebarMobile: false,
    header: "white", //other value can be passed "light,dark,theme"
    skin: defaultBrowserTheme(), //other value can be passed "dark"
  };
  useEffect(() => {
    let theme = localStorage.getItem("theme");
    defaultTheme.skin = theme || defaultBrowserTheme();

    // eslint-disable-next-line
  }, []);
  const [theme, setTheme] = useState(defaultTheme);

  const themeUpdate = {
    customLayout: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, customLayout: value }));
    },
    fullScreen: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, fullScreen: value }));
    },
    uistyle: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, main: value }));
    },
    sidebar: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, sidebar: value }));
    },
    appbar: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, appbar: value }));
    },
    sidebarVisibility: () => {
      setTheme((prevTheme) => ({
        ...prevTheme,
        sidebarVisibility: !prevTheme.sidebarVisibility,
      }));
    },
    sidebarHide: () => {
      setTheme((prevTheme) => ({ ...prevTheme, sidebarVisibility: false }));
    },
    header: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, header: value }));
    },
    skin: (value) => {
      setTheme((prevTheme) => ({ ...prevTheme, skin: value }));
    },
    reset: () => {
      setTheme((prevTheme) => ({
        ...prevTheme,
        main: defaultTheme.main,
        sidebar: defaultTheme.sidebar,
        appbar: defaultTheme.appbar,
        header: defaultTheme.header,
        skin: defaultTheme.skin,
      }));
    },
  };
  const apiAppsTheme = async () => {
    const Data = {
      action: "fetch",
    }
    try {
      const response = await postRequestData(Data, "api/v1/public/frontend-settings");
      if (response.status) {
        // console.log(response?.data?.frontend_settings);
        const apimode = response?.data?.frontend_settings?.theme === "1" ? "light" : "dark";
        if (apimode !== theme.skin) {
          themeUpdate.skin(response?.data?.frontend_settings?.theme === "1" ? "light" : "dark");
        }
      }
    } catch (error) {
      console.log(`Error From AppsTheme fuc_ in User :${error}`)
    }
  }
  useEffect(() => {
    apiAppsTheme();
  }, [triggerFetch]);

  const bodyClass = classNames({
    "nk-body has-apps-sidebar has-sidebar no-touch nk-nio-theme": true,
  });

  useEffect(() => {
    const body = document.querySelector("body");
    body.className = bodyClass;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (theme.main === "default") {
      body.classList.add("ui-default");
      body.classList.remove("ui-clean", "ui-shady");
    }
    if (theme.main === "clean") {
      body.classList.add(`ui-clean`);
      body.classList.remove("ui-default", "ui-shady");
    }
    if (theme.main === "shady") {
      body.classList.add(`ui-shady`);
      body.classList.remove("ui-default", "ui-clean");
    }
    if (theme.skin === "dark") {
      body.classList.add(`dark-mode`);
    } else {
      body.classList.remove("dark-mode");
    }
    if (theme.sidebarVisibility === true) {
      body.classList.add("nav-shown");
    } else {
      body.classList.remove("nav-shown");
    }
  }, [theme]);
  
  useEffect(() => {
    const handleMobileSidebar = () => {
      if (window.innerWidth < 1200) {
        setTheme((prevTheme) => ({ ...prevTheme, sidebarMobile: true }));
      } else {
        setTheme((prevTheme) => ({
          ...prevTheme,
          sidebarMobile: false,
          sidebarVisibility: false,
        }));
      }
    };

    handleMobileSidebar();
    window.addEventListener("resize", handleMobileSidebar);
    return () => {
      window.removeEventListener("resize", handleMobileSidebar);
    };
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeUpdateContext.Provider value={themeUpdate}>{props.children}</ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
