import React, { useState, useEffect, useContext } from "react";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import CalenderApp from "../../../components/partials/calender/Calender";
import DatePicker from "react-datepicker";
import "../../../../src/css/calender-custom.css";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Spinner,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import {
  Block,
  Col,
  Icon,
  PreviewAltCard,
  Row,
  RSelect,
} from "../../../components/Component";

import {
  eventOptions,
  events,
  getCategory,
  getCategoryValueByLabel,
  ReminderTimeOtions,
  ReminderTypeOtions,
  subMeetingOptions,
} from "../../../components/partials/calender/CalenderData";
import { useForm } from "react-hook-form";
import { MakeApiCall } from "../../../utils/Utils";
import {
  getCombinedDate,
  getCurrentDateTimeInFormat,
  checkReminder,
  handleDropChange,
  handleReminder,
  CancelReminder,
} from "../../../utils/helper";
import useUserList from "../../../utils/useUserList";
import { deleteCalendarEventApi, updateCalendarEventApi } from "./EventApi";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import MapComponent from "./mapLocation";
import { GoDotFill } from 'react-icons/go';
import { useTheme } from "../../../layout/provider/Theme";
import { TriggerBtnContext } from "../../../App";
import useStorageUsage from "../../../utils/useStorageUsage";
import { MdOutlineEditNote, MdOutlineCategory } from "react-icons/md";

const Calender = () => {

  const newTheme = useTheme();
  const [modal, setModal] = useState(false);
  const [mockEvents, updateEvent] = useState(events);
  const [modalSm, setModalSm] = useState(false);
  const toggleSm = () => setModalSm(!modalSm);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdowntoggle = () => setDropdownOpen((prevState) => !prevState);

  const [subDropdownOpen, setSubDropdownOpen] = useState(false);
  const toggleSubDropdown = () => setSubDropdownOpen(!subDropdownOpen);
  const [subMeet, setSubMeet] = useState("");
  const [filterSubMeetingOpt, setFilterSubMeetingOpt] = useState([] || null);

  const { toggleFetch, triggerFetch } = useContext(TriggerBtnContext);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
    email: [],
    users: "",
    location: "",
    subCategory: "",
    meetingLink: "",
    theme: {
      value: "",
      label: "",
    },
  });
  const [theme, settheme] = useState("");
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [files4, setFiles4] = useState([]);
  const [ReminderStore, setReminderStore] = useState([]);
  const [userInputValue, setUserInputValue] = useState('');
  const [filterUserList, setFilterUserList] = useState([] || null);
  const { storageDetails, Storageloading } = useStorageUsage();
  // Storage filled alert logic
  const [open, setOpen] = useState(false);
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const mapToggle = () => { setIsOpen(!isOpen) };
  const getTrue = (data) => {
    if (data) {
      mapToggle();
    }
  }
  const [reminderformData, setReminderFormData] = useState({
    tmp_remindertype: "",
    tmp_remindertime: "",
    tmp_minutehour: "",
  });
  const toggle = () => {
    setModal(!modal);
    let currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 15);
    setFormData({ ...formData, startTime: currentTime });
  };

  const handleChildData = (data) => {
    const [year, month, day] = data.split("-");
    const clickDate = new Date(`${month}-${day}-${year}`);
    const currentTime = new Date();
    const currentyear = currentTime.getFullYear();     // Get the year (e.g., 2024)
    const currentmonth = currentTime.getMonth() + 1;   // Get the month (1-12)
    const currentday = currentTime.getDate();          // Get the day of the month (1-31)
    const currentDate = new Date(`${currentmonth}-${currentday}-${currentyear}`);

    // Calculate the end time, which should be 15 minutes later
    const endDateTime = new Date(currentTime.getTime() + 60 * 60000);
    const startDateTime = new Date(currentTime.getTime() + 5 * 60000);

    if (clickDate >= currentDate || clickDate.toDateString() === currentDate.toDateString()) {
      setFormData({
        ...formData,
        startDate: clickDate,
        startTime: startDateTime,
        endDate: clickDate,
        endTime: endDateTime,
      });
      setModal(!modal);
    } else {
      toast.error("Previous Date's Not Available...");
    }
  };

  useEffect(() => {
    let intervalId;
    if (mockEvents.length > 0) {
      intervalId = setInterval(checkReminder(mockEvents), 3000);
    }
    return () => clearInterval(intervalId);
  }, [mockEvents]);
  useEffect(() => {
    const date = formData.startDate;
    const enddate = new Date(date);
    // setFormData({ ...formData, endDate: enddate.setDate(enddate.getDate() + 1) });
    // setFormData({ ...formData, endDate: enddate });
    setFormData(prevFormData => ({
      ...prevFormData,
      endDate: enddate
    }));
  }, [formData.startDate]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const UserList = useUserList();
  useEffect(() => {
    getEvents();
  }, [triggerFetch]);
  async function getEvents() {
    try {
      const res = await MakeApiCall(`api/v1/calendar-events/list`);
      // console.log(res);
      if (res && res.status) {
        let tempEvents = res?.data?.events.map((event) => {
          const formattedStartDateTime = getCurrentDateTimeInFormat(new Date(event.start_date_time));
          const formattedEndDateTime = getCurrentDateTimeInFormat(new Date(event.end_date_time));
          let newEvent = {
            id: "default-event-id-" + Math.floor(Math.random() * 9999999) + "-" + event.id,
            eventId: event.id,
            title: event.event_title,
            start: formattedStartDateTime,
            end: formattedEndDateTime,
            startDate: new Date(event.start_date_time),
            endDate: new Date(event.end_date_time),
            startTimeObj: new Date(event.start_date_time),
            endTimeObj: new Date(event.end_date_time),
            email: event.email ? event.email : [],
            users: event.users ? event.users : [],
            user_id: event.user_id,
            location: event.location,
            link: event.link,
            organizer_user_id: event?.organizer_user_id,
            organizer: event?.organizer_username,
            subCategory: event.subCategory,
            meetingLink: event.meetingLink,
            description: event.event_description,
            className: getCategoryValueByLabel(event.category),
            type: getCategory(getCategoryValueByLabel(event.category)),
            reminder: event.reminder ? event.reminder : [],
            parent_id: event.parent_id,
          };
          return newEvent;
        });
        updateEvent(tempEvents);
      } else {
        console.log("No events found");
      }
    } catch (error) {
      console.log("Error fetching event data:", error);
    }
  }
  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      startDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      endDate: new Date(),
      email: [],
      users: "",
      location: "",
      subCategory: "",
      meetingLink: "",
      theme: {
        value: "",
        label: "",
      },
    });
    setFiles4([]);
    setSubMeet("");
    setReminderStore([]);
    setReminderFormData({
      tmp_remindertype: "",
      tmp_remindertime: "",
      tmp_minutehour: "",
    });
  };
  const onFormCancel = () => {
    if (formData.title.length !== 0
      || formData.description.length !== 0
      || formData.email.length !== 0
      || formData.users.length !== 0
      || formData.location.length !== 0
    ) {
      setModalSm(true);
    } else {
      setModal(false);
      setFiles4([]);
      resetForm();
    }
  };

  const onFormDiscard = (e) => {
    e.preventDefault();
    setModal(false);
    toggleSm()
    setFiles4([]);
    resetForm();
  }
  const handleData = (Data) => {
    setFormData({ ...formData, location: Data });
  }
  const handleFormSubmit = async (form) => {
    setLoading(true);
    if (!formData.startDate) {
      toast.error(<div>Enter start date</div>);
      setLoading(false);
      return false;
    }
    if (!formData.endDate) {
      toast.error(<div>Enter end date</div>);
      setLoading(false);
      return false;
    }
    if (!formData.startTime) {
      toast.error(<div>Enter start time</div>);
      setLoading(false);
      return false;
    }
    if (!formData.endTime) {
      toast.error(<div>Enter end time</div>);
      setLoading(false);
      return false;
    }
    let sd = formData.startDate;
    let ed = formData.endDate;

    let sd_t = formData.startTime;
    let ed_t = formData.endTime;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);

    const formattedStartDateTime = getCurrentDateTimeInFormat(startDateTime);
    const formattedEndDateTime = getCurrentDateTimeInFormat(endDateTime);

    // var sd_ms = startDateTime.getTime();

    // var ed_ms = endDateTime.getTime();

    // var current_date = new Date();
    // var current_ms = current_date.getTime();

    // if (sd_ms - current_ms < 0) {
    //   toast.error(
    //     <div>
    //       Enter start datetime greater than
    //       <br /> current / datetime
    //     </div>
    //   );
    //   setLoading(false);
    //   return false;
    // }
    // if (ed_ms - sd_ms < 1) {
    //   toast.error(
    //     <div>
    //       Enter end date time greater than <br /> start / date time
    //     </div>
    //   );
    //   setLoading(false);
    //   return false;

    // }

    // Ensure endTime is greater than startTime
    if (startDateTime.getTime() === endDateTime.getTime()) {
      endDateTime = new Date(endDateTime.getTime() + 60000); // Add 1 min 
    }

    const userEmail = formData.email;
    let addEventForm = new FormData();
    addEventForm.append("event_title", formData.title);
    addEventForm.append("event_description", formData.description);
    addEventForm.append("start_date_time", getCurrentDateTimeInFormat(startDateTime));
    addEventForm.append("end_date_time", getCurrentDateTimeInFormat(endDateTime));
    addEventForm.append("visibility", "1");
    addEventForm.append("category", form?.theme?.label);
    addEventForm.append("subCategory", formData?.subCategory);
    addEventForm.append("meetingLink", formData?.meetingLink);
    addEventForm.append("reminder", JSON.stringify(ReminderStore));
    addEventForm.append("status", "active");
    addEventForm.append("invite_by_email", userEmail);
    addEventForm.append("invite_by_username", formData.users);
    addEventForm.append("location", formData.location);
    if (files4?.[0]) addEventForm.append("event_attachment", files4?.[0]);
    // const entries = addEventForm.entries();
    const res = await MakeApiCall(`api/v1/calendar-events/add`, addEventForm);
    if (res?.status) {
      toggleFetch(loading);
      let newEvent = {
        id: "default-event-id-" + Math.floor(Math.random() * 9999999) + "-" + res?.data?.eventData?.id,
        title: formData.title,
        eventId: res?.data?.eventData?.id,
        start: formattedStartDateTime,
        end: formattedEndDateTime,
        startDate: new Date(formattedStartDateTime),
        endDate: new Date(formattedEndDateTime),
        startTimeObj: startDateTime,
        endTimeObj: endDateTime,
        description: formData.description,
        email: res?.data?.eventData?.invited_by_email ? res?.data?.eventData?.invited_by_email : [],
        users: res?.data?.eventData?.invited_by_username ? res?.data?.eventData?.invited_by_username : [],
        location: formData.location,
        className: getCategoryValueByLabel(form?.theme?.label),
        type: getCategory(getCategoryValueByLabel(form?.theme?.label)),
        reminder: res?.data?.eventData?.reminder.length > 0 ? res?.data?.eventData?.reminder : [],
        parent_id: res?.data?.eventData?.parent_id,
      };
      const updatedEvents = [newEvent, ...mockEvents];
      updateEvent(updatedEvents);

      settheme({
        value: "",
        label: "",
      });
      toggle();
      resetForm();
      toast.success(res?.message);
    } else {
      if (res?.message?.includes("already exists")) {
        toast.error("Title already exists. Please choose a different title.");
      } else {
        const ErrorMessage = res?.errors?.length > 0 ? res?.errors[0] : res?.message;
        toast.error(ErrorMessage);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };
  const handleUserSearch = async (e) => {
    const inputValue = e.map((option) => option.value);
    setFormData({ ...formData, users: inputValue });
  };
  useEffect(() => {
    const List = UserList.filter((option) =>
      option.label.toLowerCase().includes(userInputValue.toLowerCase())
    );
    setFilterUserList(List.slice(0, 5));
  }, [setFilterUserList, UserList, userInputValue]);

  useEffect(() => {
    const list = subMeetingOptions.filter((opt) =>
      opt.label.toLowerCase().includes(subMeet.toLowerCase())
    );
    setFilterSubMeetingOpt(list);
  }, [subMeet]);

  const editEvent = async (eventId, eventData) => {
    let sd = eventData.startDate;
    let ed = eventData.endDate;

    let sd_t = eventData.startTimeObj;
    let ed_t = eventData.endTimeObj;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);

    const formattedStartDateTime = getCurrentDateTimeInFormat(startDateTime);
    const formattedEndDateTime = getCurrentDateTimeInFormat(endDateTime);

    // var sd_ms = startDateTime.getTime();

    // var ed_ms = endDateTime.getTime();

    // var current_date = new Date();
    // var current_ms = current_date.getTime();

    // if (sd_ms - current_ms < 0) {
    //   toast.error(
    //     <div>
    //       Enter start datetime greater than
    //       <br /> current / datetime
    //     </div>
    //   );
    //   return false;
    // }
    // if (ed_ms - sd_ms < 1) {
    //   toast.error(
    //     <div>
    //       Enter end datetime greater than
    //       <br /> start / datetime
    //     </div>
    //   );
    //   return false;
    // }

    const formattedEventData = {
      ...eventData,
      start: formattedStartDateTime,
      end: formattedEndDateTime,
      startTimeObj: startDateTime,
      endTimeObj: endDateTime,
      className: eventData?.type?.value,
    };
    let uploadFilesResponse = await updateCalendarEventApi(eventId, formattedEventData);
    if (uploadFilesResponse && uploadFilesResponse.status) {
      toggleFetch(loading);
      const updatedEvents = mockEvents.map((event) => (event.eventId === eventId ? formattedEventData : event));
      updateEvent(updatedEvents);
      toast.success(uploadFilesResponse.message);
      return true;
    } else if (uploadFilesResponse) {
      toast.error(uploadFilesResponse.message);
      return false;
    } else {
      toast.error("Error updating event.");
      return false;
    }
  };
  const deleteEvent = async (eventId) => {
    let responseData = await deleteCalendarEventApi(eventId);
    if (responseData.status) {
      toggleFetch(loading);
      const updatedEvents = mockEvents.filter((event) => event.eventId !== eventId);
      updateEvent(updatedEvents);
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
    }
  };
  const handleChangeTags = (tags) => {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    if (allTagsValid) {
      setFormData({ ...formData, email: tags });
    } else {
      toast.error("Enter valid email addresses");
    }
  };

  const handleDropdownSelect = (data) => {
    const { label, value } = data;
    const theme = { label: label, value: value };
    setFormData({ ...formData, theme: theme })
    setDropdownOpen(false); // Close the dropdown after selection (if needed)
  };
  const handleSubMeetingSelect = (selectedItem) => {
    setFormData({ ...formData, subCategory: selectedItem.label });
    setSubMeet(selectedItem.label);
  }
  const handleSubMeetSerach = (e) => {
    const searchQuery = e.target.value;
    setSubMeet(searchQuery);
    setFormData({ ...formData, subCategory: searchQuery });
  }
  return (
    <React.Fragment>
      {
        !Storageloading && (
          <Modal isOpen={open} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>
              <span className="fw-bold" style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>
                Storage Filled Alert!
              </span>
            </ModalHeader>
            <ModalBody>
              <p
                style={{
                  fontFamily: 'Roboto', fontWeight: '500',
                  color: theme.skin === "light" ? "#000" : "#f4bd0e"
                }}>
                Your storage has been filled with 90% or above. Please{' '}
                <a
                  href={storageDetails.upgradeLink}
                  style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}
                  className="fw-bold"
                >
                  upgrade
                </a>{' '}
                the plan.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleClose}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )
      }
      <Head title="Silo Calender" />
      <ContentAlt>
        <Block>
          <PreviewAltCard >
            <CalenderApp
              onChildData={handleChildData}
              events={mockEvents}
              onDelete={deleteEvent}
              onEdit={editEvent}
              selectedCategory={theme}
              UserListRef={UserList}
            />
          </PreviewAltCard>
        </Block>
      </ContentAlt>

      <Modal isOpen={modal} toggle={() => onFormCancel()} className="modal-lg ">
        <ModalHeader toggle={() => onFormCancel()}>Add Event</ModalHeader>
        <ModalBody className="pt-1">
          {/* <form
            className="form-validate is-alter"
            onSubmit={handleSubmit(handleFormSubmit)}
          > */}
          {/* <Button color="primary" onClick={toggleSm}>
              Modal Small
            </Button> */}
          <Modal size="sm" isOpen={modalSm} toggle={toggleSm}>
            <ModalHeader >
              <p className="fs-6">Discard unsaved changes?</p>
            </ModalHeader>
            <ModalFooter className="bg-light">
              <button
                type="button"
                onClick={toggleSm}
                className="bg-transparent fs-6 fw-bold border-0 text-secondary bttn-hover"
              >
                Cancel
              </button>
              <Button color="danger" className="btn-dim" onClick={(e) => onFormDiscard(e)} >
                Discard
              </Button>
            </ModalFooter>
          </Modal>
          <form
            className="form-validate is-alter"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(handleFormSubmit)();
            }}
          >
            <Row className="gx-4 gy-2">
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="pen" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="flex-grow-1">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-title">
                      Event Title<span className="required-star">*</span>
                    </label> */}
                    <div className="form-control-wrap">
                      <input
                        placeholder="Enter Title"
                        type="text"
                        id="event-title"
                        {...register("title", { required: true })}
                        value={formData.title}
                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        className="form-control"
                        maxLength={50}
                      />
                      {errors.title && <p className="invalid">This field is required</p>}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="calendar-alt" className="fs-4 pt-1 pe-2" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-1 me-xs-0" >
                    <div className="form-group">
                      {/* <label className="form-label">
                    Start Date <span className="required-star">*</span>
                  </label> */}
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.startDate}
                          onChange={(date) => setFormData({ ...formData, startDate: date })}
                          className="form-control date-picker"
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0" >
                    <div className="form-group">
                      {/* <label className="form-label">
                      Start Time <span className="required-star">*</span>
                    </label> */}
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={formData.startTime}
                          onChange={(date) => setFormData({ ...formData, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Icon name="arrow-right" className="fs-5 pt-1 pe-1 d-none d-md-block" />
                  <Col xs="auto" className="mb-1 me-1 me-xs-0" >
                    <div className="form-group">
                      {/* <label className="form-label">
                      End Date<span className="required-star">*</span>
                    </label> */}
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.endDate}
                          onChange={(date) => setFormData({ ...formData, endDate: date })}
                          className="form-control date-picker"
                          minDate={formData.startDate}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" >
                    <div className="form-group">
                      {/* <label className="form-label">
                      End Time<span className="required-star">*</span>
                    </label> */}
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={formData.endTime}
                          onChange={(date) => {
                            if (formData.startTime.getTime() === date.getTime()) {
                              const endDateTime = new Date(date.getTime() + 60000); // Add 1 min 
                              setFormData({ ...formData, endTime: endDateTime });
                            } else {
                              setFormData({ ...formData, endTime: date });
                            }
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <MdOutlineCategory className="fs-3 me-2" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col className="mb-1 me-1 me-xs-0" xs="auto" md={`${formData?.theme?.label && formData?.theme?.label === "Meeting"
                    ? 3 : 12}`}>
                    <div className="form-group">
                      {/* <label className="form-label">
                      Event Category
                    </label> */}
                      <div className="form-control-wrap">
                        <UncontrolledDropdown style={{ width: "100%" }} isOpen={dropdownOpen} toggle={dropdowntoggle} direction="down">
                          <DropdownToggle caret className="p-0 border-0 w-100 "
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                              // Inline style to remove the hover effect
                              ':hover': {
                                backgroundColor: "transparent",
                                border: "none",
                                color: "inherit",
                              },
                            }}>
                            <input
                              className="form-control"
                              value={typeof formData.theme === "object" ? formData?.theme?.label : formData?.theme}
                              readOnly
                              placeholder="Select Category"
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{
                              background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                            }}>
                            {eventOptions
                              .filter(item =>
                                item.label !== "Subscription Expire"
                                && item.label !== "Buy Subscription"
                                && item.label !== "Default"
                              )
                              .map((item, index) => (
                                <>
                                  <DropdownItem
                                    style={{
                                      background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                      color: "#8091a7"
                                    }}
                                    key={index}
                                    onClick={() => {
                                      handleDropdownSelect(item);
                                      dropdowntoggle();
                                    }}
                                  >
                                    <GoDotFill style={{ color: `${item.color}` }} className="me-1 fs-5" />
                                    {item.label}
                                  </DropdownItem>

                                </>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Col>
                  {
                    formData?.theme?.label && formData?.theme?.label === "Meeting" ?
                      (
                        <Col sm="auto" md="3" className="mb-1 me-1 me-xs-0">
                          <div className="form-group">
                            {/* <label className="form-label">
                            Meeting Held On
                          </label> */}
                            <div className="form-control-wrap">
                              <UncontrolledDropdown style={{ width: "100%" }} isOpen={subDropdownOpen} toggle={toggleSubDropdown} direction="down">
                                <DropdownToggle caret className="p-0 border-0 w-100"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "inherit",
                                    // Inline style to remove the hover effect
                                    ':hover': {
                                      backgroundColor: "transparent",
                                      border: "none",
                                      color: "inherit",
                                    },
                                  }}>
                                  <input
                                    className="form-control"
                                    value={subMeet}
                                    onChange={handleSubMeetSerach}
                                    placeholder="Meeting Held On"
                                  />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {filterSubMeetingOpt.map((item, index) => (
                                    <DropdownItem
                                      style={{
                                        background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                        color: "#8091a7",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        handleSubMeetingSelect(item);
                                        toggleSubDropdown();
                                      }}
                                    >
                                      {item.img ? (
                                        <img
                                          src={item.img}
                                          alt={item.label}
                                          className="me-2"
                                          style={{ height: "20px", width: "20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {item.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </Col>
                      ) : ""
                  }
                  {
                    formData?.theme?.label && formData?.theme?.label === "Meeting" ?
                      (
                        <Col xs="auto" className="w-100" >
                          <div className="form-group">
                            {/* <label className="form-label" htmlFor="link">
                              Link
                            </label> */}
                            <div className="form-control-wrap">
                              <input
                                placeholder="Enter Link"
                                type="text"
                                id="link"
                                value={formData.meetingLink}
                                onChange={(e) => setFormData({ ...formData, meetingLink: e.target.value })}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </Col>
                      ) : ("")
                  }
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <MdOutlineEditNote className="fs-2 me-1" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-description">
                      Event Description
                    </label> */}
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        id="event-description"
                        placeholder="Enter Description"
                        // {...register("description", { required: false })}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      // maxLength={255}
                      ></textarea>
                      {/* {errors.description && <p className="invalid">This field is required</p>} */}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="map-pin" className="fs-3 pt-1 pe-1" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group" >
                    {/* <label className="form-label " htmlFor="Location">
                      Location
                    </label> */}
                    <div className="form-control-wrap" id="Location">
                      <input
                        toggle={mapToggle} onClick={mapToggle}
                        className="form-control"
                        value={formData?.location}
                        readOnly
                        placeholder="Add Location"
                      />
                    </div>
                  </div>
                  <Modal isOpen={isOpen} toggle={mapToggle}>
                    <ModalHeader toggle={() => mapToggle()}>Location</ModalHeader>
                    <ModalBody>
                      <MapComponent onSendData={handleData} sendTrueValueParent={getTrue} />
                    </ModalBody>
                  </Modal>
                </Col>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="users" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label">Invite User</label> */}
                    <div className="form-control-wrap ">
                      <RSelect
                        placeholder="Add Guest's"
                        options={filterUserList}
                        isMulti
                        onChange={handleUserSearch}
                        onInputChange={(value) => {
                          setUserInputValue(value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="mail" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label">Invite By E-mail</label> */}
                    <div className="form-control-wrap">
                      <TagsInput
                        className="form-control p-0 w-100"
                        value={Array.isArray(formData.email) ? formData.email : [formData.email]}
                        onChange={handleChangeTags}
                        inputProps={{
                          className: 'w-100 mb-0 react-tagsinput-input tagsinput-color  ',
                          placeholder: 'Invite Via Mail',
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="img" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  {/* <label className="form-label">Event Image</label>
                <label className="ps-3" style={{ fontSize: "12px" }}>(Only single image can be uploaded)</label> */}
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles4)}
                    accept={[".jpg", ".png", ".svg"]}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable"
                          style={{ minHeight: "0", background: newTheme.skin === "dark" ? "#141c26" : "#a5a6a91c" }}>
                          <input {...getInputProps()} />
                          {files4.length === 0 && (
                            <div className="">
                              <span className="dz-message-text">Drag and drop file</span>
                              {/* <span className="dz-message-or">or</span>
                            <Button className="btn-outline-primary bg-transparent" type="button">
                              SELECT
                            </Button> */}
                            </div>
                          )}
                          {files4.map((file) => (
                            <div
                              key={file.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={file.preview} alt="preview" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>
              <div className="d-column-flex align-items-center w-100">
                <Col xs="auto" className="p-0 d-flex align-items-center">
                  <Icon name="alarm-alt" className="fs-3 pe-2" />
                  <label className="form-label pt-1">Reminder</label>
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Reminder</label> */}
                        <RSelect
                          options={ReminderTypeOtions}
                          onChange={(selected) =>
                            setReminderFormData({ ...reminderformData, tmp_remindertype: selected.value })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Time</label> */}
                        <input
                          placeholder="Ex :- 5 hours"
                          className="form-control"
                          type="number"
                          onChange={(e) => setReminderFormData({ ...reminderformData, tmp_minutehour: e.target.value })}
                          value={reminderformData.tmp_minutehour}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Timeframe</label> */}
                        <RSelect
                          options={ReminderTimeOtions}
                          onChange={(selected) =>
                            setReminderFormData({ ...reminderformData, tmp_remindertime: selected.value })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="1" className="">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label w-75">Add</label> */}
                        <Icon name="plus" className="fs-4 ps-3 form-control"
                          onClick={() => {
                            handleReminder(reminderformData, setReminderStore, setReminderFormData, ReminderStore);
                          }} />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
              {ReminderStore &&
                ReminderStore.map((reminder, index) => {
                  return (
                    <>
                      <div className="d-flex flex-row flex-column flex-md-row  w-100">
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                readOnly
                                value={reminder.remindertype === "sms" ? "SMS" : "Email"}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input className="form-control" type="number" readOnly value={reminder.minutehour} />
                            </div>
                          </div>
                        </Col>
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                readOnly
                                value={reminder.remindertime === "m" ? "Minutes" : "Hours"}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="1" className="">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <Icon name="cross" className="fs-4 ps-3 ps-sm-2 ps-xs-0 form-control"
                                onClick={() => CancelReminder(index, ReminderStore, setReminderStore)} />
                            </div>
                          </div>
                        </Col>
                      </div>
                    </>
                  );
                })}
              <Col size="12">
                <ul className="d-flex justify-content-center gx-5 ">
                  <li>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? (
                        <span className="align-center">
                          <Spinner size="sm" color="light" /> Loading...
                        </span>
                      ) : (
                        <span>Save Event</span>
                      )}
                    </Button>
                  </li>
                  {/* <li>
                    <Button color="danger" className="btn-dim" onClick={() => onFormCancel()}>
                      Discard
                    </Button>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal >
    </React.Fragment >
  );
};
export default Calender;