import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie } from "../../../../utils/Utils";
import AccountAvatar from "./AccountAvatar";
import Cookies from "js-cookie";
import { postRequestData } from "../../../../utils/api-services";
import { Blockchain_icon, Blockchain_icon_dark } from "../../../../images/applogos";
const User = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const appsUpdateTheme = async (event, theme) => {
    event.preventDefault();
    const Data = {
      "columnValue": theme === "dark" ? 1 : 2,
      "action": "update",
      "column": "theme",
      // "columnKey": key,
    };
    try {
      await postRequestData(Data, "api/v1/public/frontend-settings");
      // console.log(response?.data?.frontend_settings?.theme,"updateThemeca");
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    }
  };

  const setLogout = (e) => {
    e.preventDefault();
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  const linkItems = [
    {
      link: `https://silocloud.com/enumblockchain-explorer`,
      img: Blockchain_icon,
      imgDark: Blockchain_icon_dark,
      text: "BlockChain",
    },
    // {
    //   link: `https://silocloud.com/wallet-dashboard`,
    //   icon: "wallet",
    //   text: "Silo Wallet",
    // },
    // {
    //   link: `https://accounts.silocloud.io/`,
    //   icon: "setting-alt",
    //   text: "Account Settings",
    // },
  ];

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        {/* <UserAvatar icon="user-alt" className="sm" /> */}
        <AccountAvatar profileName={getCookie("username", "John")} />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            {/* <div className="user-avatar">
              <span>AB</span>
            </div> */}
            <AccountAvatar profileName={getCookie("username", "John")} />

            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "john@silocloud.io")}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* {linkItems.map((item, index) => (
              <LinkItem key={index} link={item.link} icon={item.icon} onClick={toggle} className={item.className}>
                {item.text}
              </LinkItem>
            ))} */}
            {/* <li>
              <a href={`${process.env.REACT_APP_ACCOUNT_URL}apps`} onClick={toggle}>
                <em class="icon ni ni-list"></em>
                <span>Apps</span>
              </a>
            </li> */}
            {linkItems.map((item, index) => (
              <li key={index}>
                <a key={index} href={item.link} icon={item.icon} onClick={toggle} className={` ${item.className}`}>
                  {item.img ? (
                    theme.skin === "light"
                      ? <img src={item.imgDark} alt={item.children} style={{ height: "15px" }} className="link-item-img" />
                      : <img src={item.img} alt={item.children} style={{ height: "15px" }} className="link-item-img" />
                  ) : (
                    <i className={`icon ni ${item.icon}`}></i>
                  )}
                  <span className="m-0 ms-2">{item.text}</span>
                </a>
              </li>
            ))}
            {/* <li>
              <a href={`https://silocloud.com/enumblockchain-explorer`} onClick={toggle}>
                <em class="icon ni ni-blockchain"></em>
                <span>BlockChain</span>
              </a>
            </li> */}
            <li>
              <a href={`https://silocloud.com/wallet-dashboard`} onClick={toggle}>
                <em class="icon ni ni-wallet"></em>
                <span>Silo Wallet</span>
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`} onClick={toggle}>
                <em class="icon ni ni-setting"></em>
                <span>Account Settings</span>
              </a>
            </li>
            <li>
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  appsUpdateTheme(ev, theme.skin);
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <a href={`${process.env.REACT_APP_ACCOUNT_URL}logout`}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a> */}
            <a onClick={setLogout} style={{ cursor: "pointer" }} className="ms-4">
              <Icon name="signout" className="ms-2"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
