import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
// import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

import { useTheme } from "./provider/Theme";
import Footer from "./footer/Footer";

const Layout = ({ title, app, ...props }) => {
  const theme = useTheme();

  return (
    <>
      <Head title={!title && "Loading"} />
      <AppRoot className="npc-apps apps-only">
        <AppMain>
          {/* <AppWrap> */}
            <AppHeader app={app} fixed />
            <Outlet />
            <Footer />
          {/* </AppWrap> */}
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;
