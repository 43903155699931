import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Calender from "../pages/app/calender/Calender";
import LayoutApp from "../layout/Index-app";
// import Cookies from "js-cookie"; // Add this if not already imported
// import { getCookie } from "../utils/Utils";

const Pages = () => {
  const location = useLocation();
  // const authToken = getCookie("authToken");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   if (!authToken) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });

  //     window.location.href = process.env.REACT_APP_ACCOUNT_URL;
  //   } else {
  //     try {
  //       // KYC status check logic can be added here if necessary
  //     } catch (error) {
  //       console.error("Error checking KYC status:", error);
  //     }
  //   }
  // }, [authToken]);

  return (
    <Routes>
      <Route
        // path={`${process.env.PUBLIC_URL}`}
        element={
          <LayoutApp
            app={{ icon: "calendar", theme: "bg-success-dim", text: "Silo Calendar" }}
          />
        }
      >
        <Route index element={<Calender />}></Route>
      </Route>
    </Routes>
  );
};

export default Pages;
