import { toast } from "react-toastify";
import Cookies from "js-cookie";

export function getCombinedDate(date, time) {
  var combinedDateTime = new Date(date);

  combinedDateTime.setHours(time.getHours());
  combinedDateTime.setMinutes(time.getMinutes());
  combinedDateTime.setSeconds(0);
  return combinedDateTime;
}

export function getCurrentDateTimeInFormat(date) {
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
let remindersShown = {};
export const checkReminder = (mockEvents) => {
  const current_time = new Date();
  const current_ms = current_time.getTime();
  mockEvents.forEach((event) => {
    const startTime = new Date(event.start).getTime();
    const timeDifference = startTime - current_ms;
    if (timeDifference > 0 && timeDifference <= 5 * 60 * 1000) {
      if (!remindersShown[event.id]) {
        const minutesRemaining = Math.abs(Math.floor(timeDifference / (1000 * 60)));
        toast.info(
          <div>
            <div style={{ color: "red" }}>Reminder</div>
            <div>
              Event {event.title} is starting in {minutesRemaining} minutes!
            </div>
          </div>,
          {
            autoClose: 4000,
            toastId: event.id,
          }
        );
        const notification = new Notification("Reminder", {
          body: `Event ${event.title} is starting in ${minutesRemaining} minutes!`,
          Icon: "",
        });
        notification.addEventListener("click", () => {
          window.location.href = "/";
        });

        remindersShown[event.id] = true;
      }
    } else {
      remindersShown[event.id] = false;
    }
  });
};
export const handleDropChange = (acceptedFiles, set) => {
  set(
    acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
  );
};

export const MakeApiCallBlobImage = async (url, formData ) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}` + url, {
    method: "POST",
    headers: {
      authToken: Cookies.get("authToken"),
    },
    cache: "no-store",
    body: formData,
  });
  if (response.ok) {
    const contentType = response.headers.get('content-type');

    if (contentType && (contentType === "image/png" ||
      contentType === "image/jpeg" ||
      contentType === "image/jpg" ||
      contentType === "image/gif" ||
      contentType === "image/webp" ||
      contentType === "image/svg+xml" ||
      contentType === "image/bmp" ||
      contentType === "image/tiff" ||
      contentType === "image/x-icon")) {
      const blob = await response.blob();
      if (blob) {
        const imageUrl = URL.createObjectURL(blob);
        if (imageUrl) return imageUrl;
      }
    }
    else {
      return null;
    }
  } else {
    const errorData = await response.json();

    const error = new Error("An Error occurred while getting event-attachment.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
};

// reminderHelpers.js

export const handleReminder = (reminderformData, setReminderStore, setReminderFormData, ReminderStore) => {
  if (reminderformData.tmp_remindertype === "") {
    toast.error("Please select reminder type");
    return false;
  } else if (reminderformData.tmp_minutehour === "") {
    toast.error("Please enter reminder time");
    return false;
  } else if (reminderformData.tmp_remindertime === "") {
    toast.error("Please select reminder time type");
    return false;
  } 
  // else if (ReminderStore.length >= 2) {
  //   toast.error("Only two reminders allowed");
  //   return false;
  // }

  const createReminderObj = {
    remindertype: reminderformData.tmp_remindertype,
    remindertime: reminderformData.tmp_remindertime,
    minutehour: reminderformData.tmp_minutehour,
  };

  setReminderStore((prevReminderStore) => {
    return [...prevReminderStore, createReminderObj];
  });

  setReminderFormData({
    ...reminderformData,
    tmp_minutehour: "",
  });

  return true;
};

export const CancelReminder = (indexToRemove, ReminderStore, setReminderStore) => {
  const updatedReminderStore = ReminderStore.filter((reminder, index) => index !== indexToRemove);
  setReminderStore(updatedReminderStore);
};
