import React, { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

const SearchBoxInput = ({ sendChildGeocode, addressData, sendTrueValue }) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (addressData) {
            setInputValue(addressData);
            setOpen(false);
        }
    }, [addressData]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (inputValue) {
                try {
                    const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${inputValue}&proximity=ip&access_token=pk.eyJ1IjoicHJha2FzaG5pbiIsImEiOiJjbHk4aHp6MTgwajN1MmtzYjlnd3RkZzJlIn0.c0MhCeRKmQCZ6zIYCypLaw`);
                    // console.log(response);
                    if (response.ok) {
                        const data = await response.json();
                        setSuggestions(data.features);
                        setOpen(true);
                    }
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                }
            } else {
                setSuggestions([]);
                setOpen(false);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            fetchSuggestions();
        }, 300); // Debounce input by 300ms

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue]);

    const handleData = (data) => {
        sendChildGeocode(data);
        setInputValue(data.properties.full_address);
        setOpen(false); // Close the dropdown menu when an item is clicked
    }
    const handleTrue = () => {
        sendTrueValue(true);
    }

    return (
        <>
            <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
                <DropdownToggle
                    tag="div"
                    className="p-0"
                    data-toggle="dropdown"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                // aria-expanded={open}
                >
                    <div className='d-flex flex-row justify-content-center'>
                        <input
                            className="form-control "
                            style={{ width: "400px" }}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Search for places..."
                        // onClick={() => setOpen(true)}
                        />
                        <Button
                            type="button"
                            className="bg-transparent border-1 ms-2"
                            onClick={() => handleTrue()}
                        >
                            <span className='text-danger'>Save</span>
                        </Button>
                    </div>
                </DropdownToggle>
                <DropdownMenu >
                    {inputValue && suggestions.length > 0 && (
                        <div className="bg-white shadow border d-flex flex-column rounded-2">
                            <div className="d-flex flex-column">
                                <div className="px-2">
                                    {suggestions.slice(0, 4).map((suggestion, index) => (
                                        <div
                                            key={index}
                                            toggle={() => setOpen(!open)}
                                            onClick={() => handleData(suggestion)}
                                            style={{ paddingTop: "10px", paddingBottom: "10px", cursor: "pointer" }}
                                            className='border-bottom'
                                        >
                                            <p
                                                className="text-primary d-flex cursor-pointer m-0 flex-column ms-2"
                                                style={{ gap: "0.425rem", lineHeight: "16px" }}
                                            >
                                                <span className='fw-bold text-dark'>{suggestion.properties.name}</span>
                                                <small className='fw-light text-dark'>{suggestion.properties.full_address}</small>
                                                {/* <small className='fw-light text-dark'>Latitude: {suggestion.geometry.coordinates[1]}, Longitude: {suggestion.geometry.coordinates[0]}</small> */}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default SearchBoxInput;
