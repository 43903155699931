import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Rectangle } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import SearchBoxInput from './../../components/SearchBoxInput';

// Fix for marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
};

const BOUNDS_STYLE = { weight: 1 };

function MinimapBounds({ parentMap, zoom }) {
    const minimap = useMap();

    const onClick = useCallback(
        (e) => {
            parentMap.setView(e.latlng, parentMap.getZoom());
        },
        [parentMap]
    );

    useMapEvents({
        click: onClick,
    });

    const [bounds, setBounds] = useState(parentMap.getBounds());

    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds());
        minimap.setView(parentMap.getCenter(), zoom);
    }, [minimap, parentMap, zoom]);

    useEffect(() => {
        parentMap.on('move zoom', onChange);
        return () => {
            parentMap.off('move zoom', onChange);
        };
    }, [parentMap, onChange]);

    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

function MinimapControl({ position, zoom }) {
    const parentMap = useMap();
    const mapZoom = zoom || 0;

    const minimap = useMemo(
        () => (
            <MapContainer
                style={{ height: 80, width: 80 }}
                center={parentMap.getCenter()}
                zoom={mapZoom}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                attributionControl={false}
                zoomControl={false}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
            </MapContainer>
        ),
        [mapZoom, parentMap]
    );

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{minimap}</div>
        </div>
    );
}

const MapComponent = ({ onSendData, sendTrueValueParent }) => {
    const [position, setPosition] = useState([37.09024, -95.712891]);
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);

    const getChildGeocode = (data) => {
        const lat = data.geometry.coordinates[1];
        const lng = data.geometry.coordinates[0];
        setPosition([lat, lng]);
        setAddress(data.properties.full_address);
    };

    const getTrueValue = (data) => {
        sendTrueValueParent(data);
    };

    const reverseGeocode = async (lat, lng) => {
        setLoading(true);
        try {
            const provider = new OpenStreetMapProvider();
            const results = await provider.search({ query: `${lat},${lng}` });
            if (results && results.length > 0) {
                setAddress(results[0].label);
            } else {
                console.log(`No results found for '${lat},${lng}'`);
            }
        } catch (error) {
            console.error('Error fetching reverse geocoding data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        onSendData(address);
    }, [onSendData, address]);

    const MapClickHandler = () => {
        useMapEvents({
            click: (e) => {
                const { lat, lng } = e.latlng;
                setPosition([lat, lng]);
                reverseGeocode(lat, lng);
            },
        });
        return null;
    };

    return (
        <div>
            <div className="form-group">
                <div className="form-control-wrap">
                    <SearchBoxInput
                        sendChildGeocode={getChildGeocode}
                        addressData={address}
                        sendTrueValue={getTrueValue}
                    />
                </div>
            </div>

            <MapContainer center={position} zoom={7} style={{ height: '60vh', width: '100%' }} scrollWheelZoom={true} zoomControl={false}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position}>
                    <Popup>
                        {loading ? 'Loading...' : (
                            <div>
                                <div>{address.length > 0 ? address : "United States"}</div>
                            </div>
                        )}
                    </Popup>
                </Marker>
                <MapClickHandler />
                <MinimapControl position="topright" zoom={7} />
            </MapContainer>
        </div>
    );
};

export default MapComponent;
