import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import DatePicker from "react-datepicker";
import { Popover, PopoverHeader, PopoverBody, ModalHeader, Modal, ModalBody, Button, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect, Icon } from "../../Component";
import { authLogin, setDateForPicker, shortenString } from "../../../utils/Utils";
import "../../../css/calender-custom.css";
import {
  handleDropChange,
  MakeApiCallBlobImage,
  handleReminder,
  CancelReminder,
  // getCombinedDate,
  // getCurrentDateTimeInFormat,
} from "../../../utils/helper";
import {
  eventOptions,
  // formattedUSDate,
  getCategory,
  getfullDate,
  getfullTime,
  ReminderTimeOtions,
  ReminderTypeOtions,
  subMeetingOptions,
} from "./CalenderData";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Dropzone from "react-dropzone";
import MapComponent from "../../../pages/app/calender/mapLocation";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useTheme } from "../../../layout/provider/Theme";
import "./calendar.css";
import Cookies from 'js-cookie';
import { GoDotFill } from "react-icons/go";
import { MdOutlineEditNote } from "react-icons/md";

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{extendedProps?.description}</PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

const CalenderApp = ({ events, onDelete, onEdit, UserListRef, onChildData }) => {
  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(events);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState({
    value: "",
    label: "",
  });
  const [edit, updateEditModal] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [files4, setFiles4] = useState([]);
  const [noImg, setNoImg] = useState();
  const [fileLoading, setFileLoading] = useState(false);
  const [ReminderStore, setReminderStore] = useState([]);
  const [reminderformData, setReminderFormData] = useState({
    tmp_remindertype: "",
    tmp_remindertime: "",
    tmp_minutehour: "",
  });
  const currentDate = new Date();
  const authToken = Cookies.get("authToken");
  const newTheme = useTheme();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdowntoggle = () => setDropdownOpen((prevState) => !prevState);

  const [subDropdownOpen, setSubDropdownOpen] = useState(false);
  const toggleSubDropdown = () => setSubDropdownOpen(!subDropdownOpen);
  const [subMeet, setSubMeet] = useState("");
  const [filterSubMeetingOpt, setFilterSubMeetingOpt] = useState([] || null);

  const [isOpen, setIsOpen] = useState(false);
  const mapToggle = () => { setIsOpen(!isOpen) };
  const getTrue = (data) => {
    if (data) {
      mapToggle();
    }
  }
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    reset(event);
  }, [event]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    updateEvents(events);
  }, [events]);
  const clearReminderField = () => {
    setReminderFormData({
      tmp_remindertype: "",
      tmp_remindertime: "",
      tmp_minutehour: "",
    });
  }

  const handleData = (Data) => {
    updateEvent({ ...event, location: Data });
  }
  const handleFormSubmit = async (formData) => {
    // let sd = event.start;
    // let ed = event.end;

    // let sd_t = event.startTimeObj;
    // let ed_t = event.endTimeObj;
    // let startDateTime = getCombinedDate(sd, sd_t);
    // let endDateTime = getCombinedDate(ed, ed_t);

    // const formattedStartDateTime = getCurrentDateTimeInFormat(startDateTime);
    // const formattedEndDateTime = getCurrentDateTimeInFormat(endDateTime);

    // var sd_ms = startDateTime.getTime();

    // var ed_ms = endDateTime.getTime();

    // var current_date = new Date();
    // var current_ms = current_date.getTime();

    // if (ed_ms - sd_ms < 1) {
    //   toast.error(
    //     <div>
    //       Enter end datetime greater than <br /> start datetime
    //     </div>
    //   );
    //   setLoading(false);
    //   return false;
    // }
    setLoading(true);
    setTimeout(() => {
      let newEvent = {};
      newEvent = {
        id: event.id,
        eventId: event.eventId,
        className: theme?.value,
        category: theme?.label,
        subCategory: theme?.label === "Meeting" ? event.subCategory : "",
        meetingLink: theme?.label === "Meeting" ? event.meetingLink : "",
        parent_id: event.parent_id,
        type: event.type,
        title: formData.title,
        start: event.start,
        startDate: event.startDate,
        startTimeObj: event.startTimeObj,
        end: event.end,
        endDate: event.endDate,
        endTimeObj: event.endTimeObj,
        description: formData.description,
        email: event.email,
        users: event.users,
        location: event.location,
        time: dates,
        event_image: files4?.[0],
        reminder: JSON.stringify(ReminderStore),
      };
      onEdit(event.eventId, newEvent);
      settheme("");
      setTimeout(() => {
        toggleEdit();
        setLoading(false);
      }, 2000);
    }, 2000); // Wait for 2000 milliseconds (2 seconds) before executing the code
  };
  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };
  const changeThemeByValue = (label) => {
    const te = getCategory(label);
    settheme(te);
  };
  const handleEventClick = async (info) => {
    setFiles4([]);
    setReminderStore([]);
    const event = events.find((item) => item.id === info.event._def.publicId);
    if (event.link) {
      window.location.href = event.link;
    } else {
      setFileLoading(true);
      updateEvent(event);
      const remindeArr = event?.reminder?.length > 0 ? JSON.parse(event?.reminder) : [];
      setReminderStore(remindeArr);
      changeThemeByValue(event.className);
      const te = getCategory(event.className);
      updateEvent({ ...event, type: te });
      toggle();
      const getImageBlob = new FormData();
      getImageBlob.append("calendar_id", event.eventId);
      try {
        const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, getImageBlob);

        if (result === null || result === undefined) {
          setNoImg(true);
        } else {
          setFiles4([{ preview: result, name: event.title }]);
        }
      } catch (error) {
        setNoImg(true);
      } finally {
        setFileLoading(false);
      }
    }
  };
  const handletagChange = (tags) => {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    if (allTagsValid) {
      updateEvent({ ...event, email: tags });
    } else {
      toast.error("Enter valid email addresses");
    }
  };
  const handleDateClick = (arg) => {
    if (!authToken) {
      authLogin();
    } else {
      onChildData(arg.dateStr);
    }
  }

  const handleMapLocation = (location, origin = 'current+location') => {
    if (!location) {
      console.error('Destination location is required.');
      return;
    }
    try {
      const destination = encodeURIComponent(location);
      const encodedOrigin = encodeURIComponent(origin);
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodedOrigin}&destination=${destination}`;

      window.open(mapsUrl, '_blank');
    } catch (error) {
      console.error('Error opening Google Maps:', error);
    }
  };
  useEffect(() => {
    const list = subMeetingOptions.filter((opt) =>
      opt.label.toLowerCase().includes(subMeet.toLowerCase())
    );
    setFilterSubMeetingOpt(list);
  }, [subMeet]);

  const handleDropdownSelect = (data) => {
    const { label, value } = data;
    const theme = { label: label, value: value };
    updateEvent({ ...event, type: theme });
    setDropdownOpen(false); // Close the dropdown after selection (if needed)
  };
  const handleSubMeetingSelect = (selectedItem) => {
    updateEvent({ ...event, subCategory: selectedItem.label });
    setSubMeet(selectedItem.label);
  }
  const handleSubMeetSerach = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSubMeet(searchQuery);
    updateEvent({ ...event, subCategory: searchQuery });
  }
  const handleCopy = (link) => {
    if (link) {
      navigator.clipboard.writeText(link)
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin]}
        events={mockEvents}
        eventClick={handleEventClick}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next title",
          center: null,
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        themeSystem="bootstrap5"
        dateClick={handleDateClick}
        contentHeight={"calc(100vh - 185px)"}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={false}
        droppable={false}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        defaultTimedEventDuration={null}
        dayMaxEventRows={3}
        fixedWeekCount={false}
        views={{
          customMonth: {
            type: "dayGridMonth",
            dayMaxEvents: true,
          },
        }}
      />
      <Modal isOpen={modalState} toggle={toggle} className={`modal-${event?.users?.length > 0 ? "xl" : "lg"}`}>
        <ModalHeader className={`${event?.className} ${"fc-event-model-header"}`} toggle={toggle}>
          {event?.title}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-3 py-1">
            <Col sm={`${event?.users?.length > 0 ? "4" : "6"}`}>
              <h6 className="overline-title">Date</h6>
              <div className="d-flex mb-1">
                <p id="preview-event-start ">{getfullDate(event?.start)}</p>
                {
                  getfullDate(event?.start) === getfullDate(event?.end)
                    ? ""
                    : (
                      <>
                        &nbsp; - &nbsp;
                        <p id="preview-event-start">{getfullDate(event?.end)}</p>
                      </>
                    )
                }
              </div>
              <h6 className="overline-title">Time</h6>
              <div className="d-flex">
                <p id="preview-event-end">{getfullTime(event?.start)}</p>
                &nbsp; - &nbsp;
                <p id="preview-event-end">{getfullTime(event?.end)}</p>
              </div>
              {
                fileLoading ?
                  <ShimmerThumbnail height={150} width={260} rounded />
                  : noImg === null ?
                    // <img src={placeHolderImg} style={{ height: "150px", width: "260px" }} />
                    ""
                    : (
                      files4.map((file) => (
                        <span className="align-center" style={{ height: "150px", width: "260px" }}>
                          <img key={file.name} src={file.preview} alt="preview" style={{ height: "auto", width: "auto" }} />
                        </span>
                      ))
                    )
              }
            </Col>
            <Col sm={`${event?.users?.length > 0 ? "4" : "6"}`}>
              <div className="d-flex flex-row justify-content-between mb-1">
                <div>
                  <h6 className="overline-title">Category</h6>
                  <p id="preview-event-category">{event?.type?.label}</p>
                </div>
                <div>
                  {
                    event?.subCategory &&
                    <>
                      <h6 className="overline-title">Meeting Held On</h6>
                      <p id="preview-event-category">{event?.subCategory}</p>
                    </>
                  }
                </div>
              </div>
              <h6 className="overline-title">{event?.meetingLink && "Meeting Link"}</h6>
              <div className="d-flex flex-row justify-content-between">
                <p id="preview-event-category " className="pointer"
                  onClick={() => window.location.href = event?.meetingLink}>
                  {event?.meetingLink && shortenString(event?.meetingLink, 40)}
                </p>
                {
                  event?.meetingLink && (
                    <>
                      <div id="copyIcon">
                        <Icon name="copy" className="fs-5 px-1 fw-bold" onClick={() => handleCopy(event?.meetingLink)} />
                      </div>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="copyIcon"
                        toggle={toggleTooltip}
                      >
                        Copy Link
                      </Tooltip>
                    </>
                  )
                }
              </div>

              {
                event?.description &&
                <>
                  <h6 className="overline-title">Description</h6>
                  <p id="preview-event-description" style={{ maxHeight: "100px", overflow: "auto" }}
                    className="text-break custom-scrollbar">
                    {event?.description}
                  </p>
                </>
              }
              {
                event?.location &&
                <p id="preview-event-category"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleMapLocation(event?.location)}
                >
                  <em class="icon ni ni-map-pin-fill fs-5 pe-1" />
                  {event?.location}
                </p>
              }
            </Col>
            {
              event?.users && event?.users?.length > 0 &&
              <Col sm="4" className="px-5">
                <h6 className="overline-title">Organiser</h6>
                <p id="preview-event-description" className="text-break">{event?.organizer}</p>
                <h6 className="overline-title">Invited Guest</h6>
                <div className="overflow-auto custom-scrollbar" style={{ maxHeight: "140px" }}>
                  {
                    event?.users?.map((item, index) => (
                      <p key={index} id="preview-event-description"
                        className="text-break mb-0 border-bottom">
                        {item}
                      </p>
                    ))
                  }
                </div>
              </Col>
            }
          </Row>
          <ul className="d-flex justify-content-between gx-4 mt-3">
            {event?.parent_id === "null" || event?.parent_id === "" || event?.parent_id === null ?
              (
                <>
                  <li>
                    {
                      currentDate <= event?.endDate &&
                      <Button
                        color="primary"
                        onClick={() => {
                          toggle();
                          toggleEdit();
                        }}
                      >
                        Edit Event
                      </Button>
                    }
                  </li>
                  <li className="ms-auto">
                    <Button
                      color="danger"
                      className="btn-dim"
                      onClick={() => {
                        toggle();
                        onDelete(event?.eventId);
                      }}
                    >
                      Delete
                    </Button>
                  </li>
                </>
              )
              : ("")
            }


          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={edit} toggle={toggleEdit} className="modal-lg">
        <ModalHeader toggle={toggleEdit}>Edit Event</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="pen" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-title">
                      Event Title<span className="required-star">*</span>
                    </label> */}
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="event-title"
                        {...register("title", { required: true })}
                        className="form-control"
                        value={event.title}
                        onChange={(e) => updateEvent({ ...event, title: e.target.value })}
                        maxLength={50}
                      />
                      {errors.title && <p className="invalid">This field is required</p>}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="calendar-alt" className="fs-4 pt-1 pe-2" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-1 me-xs-0">
                    <div className="form-group">
                      {/* <label className="form-label">
                        Start Date<span className="required-star">*</span>
                      </label> */}
                      <div className="form-control-wrap">
                        <DatePicker
                          minDate={new Date()}
                          selected={new Date(event.start)}
                          onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date), startDate: date })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0">
                    <div className="form-group">
                      {/* <label className="form-label">
                        Start Time<span className="required-star">*</span>
                      </label> */}
                      <div className="form-control-wrap has-timepicker testp">
                        <DatePicker
                          selected={event.startTimeObj}
                          onChange={(date) => updateEvent({ ...event, startTimeObj: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Icon name="arrow-right" className="fs-5 pt-1 pe-1 d-none d-md-block" />
                  <Col xs="auto" className="mb-1 me-1 me-xs-0">
                    <div className="form-group">
                      {/* <label className="form-label">
                        End Date<span className="required-star">*</span>
                      </label> */}
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.end)}
                          onChange={(date) => updateEvent({ ...event, end: setDateForPicker(date), endDate: date })}
                          className="form-control date-picker"
                          minDate={event.startDate}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <div className="form-group">
                      {/* <label className="form-label">
                        End Time<span className="required-star">*</span>
                      </label> */}
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={event.endTimeObj}
                          onChange={(date) => {
                            if (event.startTimeObj.getTime() === date.getTime()) {
                              const endDateTime = new Date(date.getTime() + 60000); // Add 1 min 
                              updateEvent({ ...event, endTimeObj: endDateTime });
                            } else {
                              updateEvent({ ...event, endTimeObj: date });
                            }
                          }
                            // updateEvent({...event, endTimeObj: date })
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="calendar-alt" className="fs-5 pt-1 pe-2" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col className="mb-1 me-1 me-xs-0" xs="auto" md={`${event?.type?.label && event?.type?.label === "Meeting"
                    ? 3 : 12}`}>
                    <div className="form-group">
                      {/* <label className="form-label">
                        Event Category
                      </label> */}
                      <div className="form-control-wrap">
                        <UncontrolledDropdown style={{ width: "100%" }} isOpen={dropdownOpen} toggle={dropdowntoggle} direction="down">
                          <DropdownToggle caret className="p-0 border-0 w-100"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                              // Inline style to remove the hover effect
                              ':hover': {
                                backgroundColor: "transparent",
                                border: "none",
                                color: "inherit",
                              },
                            }}>
                            <input
                              className="form-control"
                              value={typeof event?.type === "object" ? event?.type?.label : event?.type}
                              readOnly
                              placeholder="Select Category"
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{
                              background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                            }}>
                            {eventOptions
                              .filter(item =>
                                item.label !== "Subscription Expire"
                                && item.label !== "Buy Subscription"
                                && item.label !== "Default"
                              )
                              .map((item, index) => (
                                <>
                                  <DropdownItem
                                    style={{
                                      background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                      color: "#8091a7"
                                    }}
                                    key={index}
                                    onClick={() => {
                                      handleDropdownSelect(item);
                                      dropdowntoggle();
                                    }}
                                  >
                                    <GoDotFill style={{ color: `${item.color}` }} className="me-2" />
                                    {item.label}
                                  </DropdownItem>

                                </>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Col>
                  {
                    event?.type?.label && event?.type?.label === "Meeting" ?
                      (
                        <Col sm="auto" md="3" className="mb-1 me-1 me-xs-0">
                          <div className="form-group">
                            {/* <label className="form-label">
                              Meeting Held On
                            </label> */}
                            <div className="form-control-wrap">
                              <UncontrolledDropdown style={{ width: "100%" }} isOpen={subDropdownOpen} toggle={toggleSubDropdown} direction="down">
                                <DropdownToggle caret className="p-0 border-0 w-100"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "inherit",
                                    // Inline style to remove the hover effect
                                    ':hover': {
                                      backgroundColor: "transparent",
                                      border: "none",
                                      color: "inherit",
                                    },
                                  }}>
                                  <input
                                    className="form-control"
                                    value={event.subCategory}
                                    onChange={handleSubMeetSerach}
                                    placeholder="Select"
                                  />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {filterSubMeetingOpt.map((item, index) => (
                                    <DropdownItem
                                      style={{
                                        background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                        color: "#8091a7",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        handleSubMeetingSelect(item);
                                        toggleSubDropdown();
                                      }}
                                    >
                                      {item.img ? (
                                        <img
                                          src={item.img}
                                          alt={item.label}
                                          className="me-2"
                                          style={{ height: "20px", width: "20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {item.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </Col>
                      ) : ""
                  }
                  {
                    event?.type?.label && event?.type?.label === "Meeting" ?
                      (
                        <Col xs="auto" className="w-100">
                          <div className="form-group">
                            {/* <label className="form-label" htmlFor="link">
                              Link
                            </label> */}
                            <div className="form-control-wrap">
                              <input
                                placeholder="Link"
                                type="text"
                                id="link"
                                value={event.meetingLink}
                                onChange={(e) => updateEvent({ ...event, meetingLink: e.target.value })}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </Col>
                      ) : ("")
                  }
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <MdOutlineEditNote className="fs-2 me-1" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-description">
                      Event Description
                    </label> */}
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        id="event-description"
                        // {...register("description", { required: false })}
                        value={event.description}
                        onChange={(e) => updateEvent({ ...event, description: e.target.value })}
                        maxLength={255}
                      ></textarea>
                      {/* {/ {errors.description && <p className="invalid">This field is required</p>} /} */}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="map-pin" className="fs-3 pt-1 pe-1" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group" >
                    {/* <label className="form-label " htmlFor="Location">
                      Location
                    </label> */}
                    <div className=" d-flex flex-row form-control-wrap" id="Location">
                      <input
                        toggle={mapToggle} onClick={mapToggle}
                        className="form-control"
                        value={event?.location}
                        readOnly
                        placeholder="Add Location"
                      />
                    </div>
                  </div>
                  <Modal isOpen={isOpen} toggle={mapToggle}>
                    <ModalHeader toggle={() => mapToggle()}>Location</ModalHeader>
                    <ModalBody>
                      <MapComponent onSendData={handleData} sendTrueValueParent={getTrue} />
                    </ModalBody>
                  </Modal>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="users" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label">Invite User</label> */}
                    <div className="form-control-wrap ">
                      <RSelect
                        placeholder="Add Guest's"
                        options={UserListRef}
                        isMulti
                        defaultValue={
                          Array.isArray(event.users)
                            ? event.users.map((username) => ({ value: username, label: username }))
                            : []
                        }
                        onChange={(selectedOption) => {
                          const inputValue = selectedOption.map((option) => option.value);
                          updateEvent({ ...event, users: inputValue }); // Update type with selected value
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="mail" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label">Invite By E-mail</label> */}
                    <div className="form-control-wrap">
                      <TagsInput
                        className="form-control p-0 w-100"
                        inputProps={{
                          className: 'w-100 react-tagsinput-input tagsinput-color secondary-text ',
                          placeholder: 'Invite Via Mail',
                        }}
                        value={Array.isArray(event.email) ? event.email : [event.email]}
                        onChange={handletagChange}
                      />

                      {/*  {errors.email && <p className="invalid">This field is required</p>}  */}
                    </div>
                  </div>
                </Col>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="p-0">
                  <Icon name="img" className="fs-4 pt-1 pe-2" />
                </Col>
                <Col xs="auto" className="w-100">
                  {/* <label className="form-label">Event Image</label> */}
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles4)}
                    accept={[".jpg", ".png", ".svg"]}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable"
                          style={{ minHeight: "0", background: newTheme.skin === "dark" ? "#141c26" : "#a5a6a91c" }}>
                          <input {...getInputProps()} />

                          {files4.length === 0 && (
                            <div className="">
                              <span className="dz-message-text">Drag and drop file</span>
                              {/* <Icon name="img" className="fs-4 pt-1" /> */}
                              {/* <span className="dz-message-text">Drag and drop file</span>
                            <span className="dz-message-or">or</span>
                            <Button color="primary">SELECT</Button> */}
                            </div>
                          )}
                          {files4.map((file) => (
                            <div
                              key={file.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={file.preview} alt="preview" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>

              <div className="d-column-flex align-items-center w-100">
                <Col xs="auto" className="p-0 d-flex align-items-center">
                  <Icon name="alarm-alt" className="fs-3 pe-2" />
                  <label className="form-label pt-1">Reminder</label>
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Reminder</label> */}
                        <RSelect
                          options={ReminderTypeOtions}
                          onChange={(selected) =>
                            setReminderFormData({ ...reminderformData, tmp_remindertype: selected.value })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Time</label> */}
                        <input
                          placeholder="Reminder"
                          className="form-control"
                          type="number"
                          onChange={(e) => setReminderFormData({ ...reminderformData, tmp_minutehour: e.target.value })}
                          value={reminderformData.tmp_minutehour}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        {/* <label className="form-label">Timeframe</label> */}
                        <RSelect
                          options={ReminderTimeOtions}
                          onChange={(selected) =>
                            setReminderFormData({ ...reminderformData, tmp_remindertime: selected.value })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="1">
                    <div>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          {/* <label className="form-label w-75 ms-2">Add</label> */}
                          <Icon name="plus" className="fs-4 ps-3 form-control"
                            onClick={() => {
                              handleReminder(reminderformData, setReminderStore, setReminderFormData, ReminderStore);
                              clearReminderField();
                            }} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>

              {ReminderStore &&
                ReminderStore.map((reminder, index) => {
                  return (
                    <>
                      <div className="d-flex flex-row flex-column flex-md-row  w-100">
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="">
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                readOnly
                                value={reminder.remindertype === "sms" ? "SMS" : "Email"}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="">
                            <div className="form-control-wrap">
                              <input className="form-control" type="number" readOnly value={reminder.minutehour} />
                            </div>
                          </div>
                        </Col>
                        <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                          <div className="">
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                readOnly
                                value={reminder.remindertime === "m" ? "Minutes" : "Hours"}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <Icon name="cross" className="fs-4 ps-3 ps-sm-2 ps-xs-0 form-control"
                                onClick={() => CancelReminder(index, ReminderStore, setReminderStore)} />
                            </div>
                          </div>
                        </Col>
                      </div>
                    </>
                  );
                })}
              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? (
                        <span className="align-center">
                          <Spinner size="sm" color="light" /> Loading...
                        </span>
                      ) : (
                        <span>Update Event</span>
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" className="btn-dim" onClick={toggleEdit}>
                      Discard
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};

export default CalenderApp;