import { useState, useEffect } from "react";
import { MakeApiCall } from "./Utils";

// import React from "react";

const useUserList = () => {
  const [UserList, setUserList] = useState([]);

  useEffect(() => {
    getUserList();
  }, []);
  const getUserList = async () => {
    try {
      const userData = await MakeApiCall(`api/v1/public/users`);
      if (userData && userData.status) {
        const userArr = userData?.data?.UserNames;
        const userOptions = userArr.map((user, index) => ({
          value: user,
          label: user,
        }));

        setUserList(userOptions);
      } else {
        console.log("User data is invalid or missing required properties");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };
  return UserList;
};

export default useUserList;
