import React, { useEffect, useState, useRef } from 'react';
import { Block } from '../../../../components/Component';
import { Col, Row, Spinner } from 'reactstrap';
import { MakeApiCall } from '../../../../utils/Utils';
import { formattedUSDate } from '../../../../components/partials/calender/CalenderData';
import { MakeApiCallBlobImage } from '../../../../utils/helper';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { placeHolderImg } from '../../../../images/applogos';
import { useTheme } from '../../../provider/Theme';

const EventNotification = ({ onSendChildData, eventId }) => {
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [files4, setFiles4] = useState([]);
    const [noImg, setNoImg] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const theme = useTheme();
    const imageCache = useRef({});

    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true);
            try {
                const res = await MakeApiCall('api/v1/calendar-events/list');
                if (res?.status) {
                    setData(res?.data?.events || []);
                }
            } catch (error) {
                console.log(`Error From Event Notification Component: ${error}`);
            } finally {
                setLoading(false);
            }
        };
        fetchEvent();
    }, [eventId]);

    useEffect(() => {
        const fetchImage = async (event) => {
            setFileLoading(true);

            if (imageCache.current[event.id]) {
                setFiles4([{ preview: imageCache.current[event.id], name: event.title }]);
                setFileLoading(false);
            } else {
                try {
                    const formData = new FormData();
                    formData.append("calendar_id", event.id);
                    const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, formData);
                    
                    if (result) {
                        imageCache.current[event.id] = result;
                        setFiles4([{ preview: result, name: event.title }]);
                    } else {
                        setNoImg(true);
                    }
                } catch (error) {
                    console.log(`Error fetching image: ${error}`);
                    setNoImg(true);
                } finally {
                    setFileLoading(false);
                }
            }
        };

        if (eventId && data.length) {
            const event = data.find((e) => e.id === eventId);
            if (event) {
                setEvent(event);
                onSendChildData(event);
                fetchImage(event);
            }
        }
    }, [data, eventId, onSendChildData]);

    if (loading) {
        return (
            <Block>
                <Row className="gy-3 py-1">
                    <Col sm="6" className="event-image">
                        <span className="align-center">
                            <Spinner size="sm" color="light" /> &nbsp;&nbsp;&nbsp;&nbsp;Loading...
                        </span>
                    </Col>
                </Row>
            </Block>
        );
    }

    if (!event) {
        return (
            <Block>
                <Row className="gy-3 py-1">
                    <Col sm="6">
                        <p>No event found</p>
                    </Col>
                </Row>
            </Block>
        );
    }

    return (
        <Block>
            <Row className="gy-3 py-1">
                <Col sm="6">
                    <h6 className="overline-title">Start Time</h6>
                    <p id="preview-event-start">{formattedUSDate(event.start_date_time)}</p>
                </Col>
                <Col sm="6" id="preview-event-end-check">
                    <h6 className="overline-title">End Time</h6>
                    <p id="preview-event-end">{formattedUSDate(event.end_date_time)}</p>
                </Col>
                <Col sm="6" className="event-image">
                    {fileLoading ? (
                        <ShimmerThumbnail
                            height={170}
                            width={260}
                            rounded
                            style={{
                                background: theme.skin === "light" ? '#333' : '#e0e0e0',
                            }}
                        />
                    ) : noImg ? (
                        // <img src={placeHolderImg} alt='placeHolderImg' style={{ height: "150px", width: "260px" }} />
                        ""
                    ) : (
                        files4.map((file) => (
                            <span className="align-center" key={file.name}>
                                <img src={file.preview} alt="preview" style={{ height: "150px", width: "260px" }} />
                            </span>
                        ))
                    )}
                </Col>
                <Col sm="6" id="preview-event-description-check">
                    <h6 className="overline-title">Category</h6>
                    <p id="preview-event-category">{event.category}</p>
                    <h6 className="overline-title">Description</h6>
                    <p id="preview-event-description" className="text-break">{event.event_description}</p>
                    <h6 className="overline-title align-items-center">Location</h6>
                    <p id="preview-event-location" style={{ cursor: "pointer" }}>
                        <em className="icon ni ni-map-pin-fill fs-5 pe-1" />
                        {event.location}
                    </p>
                </Col>
            </Row>
        </Block>
    );
};

export default EventNotification;
